import CoreValues from "../components/CoreValues";
import Header from "../components/Header";
import OurWorks from "../components/OurWorks";
import WhoWeAre from "../components/WhoWeAre";

const Home = () => {
    return (
        <div>
            <Header title='Round Care Development Initiatives' para="Round Care Development Initiatives is a Not-for-Profit sex worker's led community-based Organization in Nigeria" button={true} />
            <WhoWeAre />
            <CoreValues />
            <OurWorks />
        </div>
    );
}

export default Home;