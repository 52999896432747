import logo from '../images/logo.png';
import hero from '../images/hero.svg';
import whoweare from '../images/whoweare.jpg';

import gallery1 from '../images/gallery/gallery1.jpg';
import gallery2 from '../images/gallery/gallery2.jpg';
import gallery3 from '../images/gallery/gallery3.jpg';
import gallery4 from '../images/gallery/gallery4.jpg';
import gallery5 from '../images/gallery/gallery5.jpg';
import gallery6 from '../images/gallery/gallery6.jpg';
import gallery7 from '../images/gallery/gallery7.jpg';
import gallery8 from '../images/gallery/gallery8.jpg';
import gallery9 from '../images/gallery/gallery9.jpg';
import gallery10 from '../images/gallery/gallery10.jpg';

import ceo from '../images/management-staffs/ceo.jpg';
import po from '../images/management-staffs/po.jpg';
import fao from '../images/management-staffs/fao.jpg';
import meo from '../images/management-staffs/meo.jpg';
import cso from '../images/management-staffs/cso.jpg';

export const images = {
    logo,
    hero,
    whoweare,
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10
}

export const navLinks = [
    {
        id: 1,
        route: '/',
        label: 'Home',
    },
    {
        id: 2,
        route: '/about-us',
        label: 'About us',
    },
    {
        id: 3,
        route: '/gallery',
        label: 'Gallery',
    },
    {
        id: 4,
        route: '/contact-us',
        label: 'Contact us',
    },
    {
        id: 5,
        route: '/donate',
        label: 'Donate now',
    },
];

export const managementStaffs = [
    {
        id: 1,
        name: 'Augustina Adanna Nwaneri',
        img: ceo,
        title: 'Executive Director',
        description: 'Augustina Adanna Nwaneri is a community member who has built her capacity over the years working on various projects. She has over 8 years of experience in HIV response programs among FSWs. She has worked as a peer educator, counselor tester, community facilitator, outreach coordinator, and monitoring and evaluation officer. She is currently the co-coordinator of the National Key Population Secretariat in Lagos State. Augustina holds a BSc in History and International relations from the Abia State University of Uturu. has attended various trainings ranging from Monitoring and Evaluation, Organization development and Sustainability, Finance and Leadership management trainings to mention a few.'
    },
    {
        id: 2,
        name: 'Kehinde Fayemi',
        img: po,
        title: 'Program Officer',
        description: 'Kehinde Fayemi is a community member who has raised her career in community work. She has over twenty years of experience working in the HIV intervention space in Lagos State, as a counselor tester, care and support, and outreach coordinator. Kehinde has a National Certificate in Education from the Adeniran Ogunsanya College of Education Lagos and has attended various training and capacity-building programs in various aspects of development work, ranging from program implementation, Financial Management, HIV counseling skills, Documentation and Reporting writing, and data management.'
    },
    {
        id: 3,
        name: 'Joseph Ahmed Lookman',
        img: fao,
        title: 'Finance and Administration Officer',
        description: 'Joseph Ahmed Lookman has over 13 years of accounting experience, with hands-on job training in various aspects of financial Management, Joseph olds a Higher National Diploma (HND) in accounting and previously worked with Zenith Bank Plc and currently building his capacity working on the KP CARE 1 project.'
    },
    {
        id: 4,
        name: 'Temitope Tijani',
        img: meo,
        title: 'Monitoring and Evaluation Officer',
        description: 'Temitope Tijani has worked as a Peer Educator and a Data Collector for over two years and has shown dedication and hard work in KP Programming. Temitope holds a BSC in Demography and Social Statistics and has attended several pieces of training including peer education training, Data collection, and analysis, and Evidence-based advocacy training. She is a Leave No Nigerian Behind (LeNNIB) Champion.'
    },
    {
        id: 5,
        name: 'Elizabeth Thomas',
        img: cso,
        title: 'Care and Support Officer',
        description: "Elizabeth Thomas has worked as a Focal Service Provider (FSP) for organizations working in the intersection of HIV /AIDS. Elizabeth holds a Diploma in Community Health and a BSC in Public health. She has attended Person-Centered training, providing support tailored to each individual's needs and preferences."
    },
];

export const galleryImages = [
    {
        id: 1,
        img: gallery1,
        text: 'A volunteer of ROCDI conducting HTS on a community member'
    },
    {
        id: 2,
        img: gallery2,
        text: 'Program officer of ROCDI engaging community stake holder in Makoko area of Lagos state'
    },
    {
        id: 3,
        img: gallery3,
        text: "ROCDI staff, volunteers and community members commemorating the international women's day"
    },
    {
        id: 4,
        img: gallery4,
        text: 'Training of our volunteers on PREP uptake and documentation'
    },
    {
        id: 5,
        img: gallery5,
        text: 'Staffs and volunteers of ROCDI'
    },
    {
        id: 6,
        img: gallery6,
        text: 'Staffs and volunteers of ROCDI'
    },
    {
        id: 7,
        img: gallery7,
        text: 'Stake holders engagement meeting at Makoko'
    },
    {
        id: 8,
        img: gallery8,
        text: 'Clothing and accommodation support for a community member'
    },
    {
        id: 9,
        img: gallery9,
        text: 'Gender norms session with community members at Oyingbo ghetto Lagos mainland'
    },
    {
        id: 10,
        img: gallery10,
        text: 'Gender norms session and HTS at a hotspot in Surulere'
    },
];