import ContactDetails from "../components/ContactDetails";
import Header from "../components/Header";

const Contact = () => {
    return (
        <div>
            <Header title='Contact us' para='Get in touch with us' button={false} />

            <ContactDetails />
        </div>
    );
}

export default Contact;