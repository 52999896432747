import { BrowserRouter, Route, Routes } from "react-router-dom";

import RootLayout from './layouts/RootLayout';

import Home from './routes/Home';
import About from './routes/About';
import Gallery from './routes/Gallery';
import Contact from './routes/Contact';
import Donate from "./routes/Donate";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/donate' element={<Donate />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;