import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const WhatWeDo = () => {
    useGSAP(() => {
        gsap.from('#wwd-title', {
            scrollTrigger: {
                trigger: '#wwd-title'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });

        gsap.from('#wwd-box', {
            scrollTrigger: {
                trigger: '#wwd-box',
            },
            duration: 1,
            opacity: 0,
            y: 130,
            scale: 0,
            stagger: 0.25
        });
    }, []);

    return (
        <div className="p-[30px]">
            <h2 id="wwd-title" className='text-[30px] font-bold'>What we do</h2>
            <p id="wwd-title" className="text-[20px]">Our area of focus</p>

            <div className="flex flex-wrap justify-start gap-[25px] w-full mt-4">
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Health</h1>
                    <p>We provide access to health services, HIV/STI testing, and harm reduction resources like condoms, safe sex kits, and needle exchange programs.</p>
                </div>
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Advocacy and Policy Reform</h1>
                    <p>We advocate for the rights and safety of sex workers, pushing for policy reforms, and fighting against discriminatory laws and practices.</p>
                </div>
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Community Support and Peer Services</h1>
                    <p>We offer peer support groups, counseling, and social services to address the unique challenges faced by sex workers.</p>
                </div>
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Economic Empowerment</h1>
                    <p>We provide resources for education, job training, and alternative income opportunities to support sex workers who want to transition out of the industry.</p>
                </div>
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Safety and Violence Prevention</h1>
                    <p>We create safety networks, emergency response systems, and support services for sex workers who have experienced violence or exploitation.</p>
                </div>
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Legal Support and Know-Your-Rights Education</h1>
                    <p>We offer legal resources, Human right and Gender norms education, support for sex workers.</p>
                </div>
                <div id="wwd-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Stigma Reduction and Public Awareness</h1>
                    <p>We challenge harmful stereotypes and promoting public understanding and acceptance of sex workers' rights and dignity.</p>
                </div>
            </div>
        </div>
    );
}

export default WhatWeDo;