import { Link } from "react-router-dom";
import { images } from "../assets/constants";
import { FaAngleRight, FaEnvelope, FaLocationDot, FaPhone } from "react-icons/fa6";

const Footer = () => {
    return (
        <footer className="bg-black text-white flex lg:flex-row lg:space-y-0 space-y-[30px] flex-col justify-between p-[30px]">
            <div className="lg:w-[calc(100%/2-30px)] w-full">
                <div className="mb-4">
                    <img src={images.logo} alt="Logo" />
                </div>
                <p>
                    Round Care Development Initiatives is a Not-for-Profit sex worker's led community-based Organization in Nigeria,   on a strong desire to aid the growth of sex workers in their diversity through, capacity building, meaningful communal engagement, encouraging the community to participate in decision making, and the prevention of gender-based violence, stigma, and discrimination, vulnerability to HIV/AIDS, STI, Hepatitis, TB and sexual and reproductive health issues
                </p>
            </div>
            <div className="lg:w-[calc(100%/3-30px)] w-full lg:ml-[40px]">
                <div className="lg:mb-[40px] mb-[20px]">
                    <h2 className="text-white font-bold text-[30px]">Quick Links</h2>
                </div>
                <ul className="space-y-4">
                    <li className="flex items-center justify-start gap-2 hover:ml-2 hover:text-blue transform transition-all"><FaAngleRight /> <Link to='/'>Home</Link></li>
                    <li className="flex items-center justify-start gap-2 hover:ml-2 hover:text-blue transform transition-all"><FaAngleRight /> <Link to='/about-us'>About</Link></li>
                    <li className="flex items-center justify-start gap-2 hover:ml-2 hover:text-blue transform transition-all"><FaAngleRight /> <Link to='/gallery'>Gallery</Link></li>
                    <li className="flex items-center justify-start gap-2 hover:ml-2 hover:text-blue transform transition-all"><FaAngleRight /> <Link to='/contact'>Contact</Link></li>
                    <li className="flex items-center justify-start gap-2 hover:ml-2 hover:text-blue transform transition-all"><FaAngleRight /> <Link to='/donate'>Donate</Link></li>
                </ul>
            </div>
            <div className="lg:w-[calc(100%/3-30px)] w-full">
                <div className="lg:mb-[40px] mb-[20px]">
                    <h2 className="text-white font-bold text-[30px]">Contact</h2>
                </div>
                <div className="space-y-4">
                    <div className="flex items-center justify-start gap-2">
                        <FaLocationDot />
                        <p>06, Aiyegbami Street, Agege, Lagos</p>
                    </div>
                    <div className="flex items-center justify-start gap-2">
                        <FaPhone />
                        <p>0813 094 1791, 0805 563 0557</p>
                    </div>
                    <div className="flex items-center justify-start gap-2">
                        <FaEnvelope />
                        <p>roundcaredevelopment@gmail.com</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;