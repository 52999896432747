import GalleryImgs from "../components/GalleryImgs";
import Header from "../components/Header";

const Gallery = () => {
    return (
        <div>
            <Header title='Our Gallery' para='See some of our works' button={false} />

            <GalleryImgs />
        </div>
    );
}

export default Gallery;