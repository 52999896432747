import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const OurSuccessStory = () => {
    useGSAP(() => {
        gsap.from('#ss-text', {
            scrollTrigger: {
                trigger: '#ss-text'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });
    }, []);

    return (
        <div className="p-[30px]">
            <h2 id='ss-text' className="mb-3 text-[25px] font-bold">Our Success Story</h2>
            <p id='ss-text' className="leading-[30px]">We have Provided small business startup funds for 3 community members and accommodation for a community member at Isopako area of Lagos Mainland. Our structure has created capacity building for more than 200 FSWs and jobs for more than 40 sex workers who have worked and currently working as volunteers, ROCDI has encouraged her community to acquire vocational skills</p>
        </div>
    );
}

export default OurSuccessStory;