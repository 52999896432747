import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const OurObjective = () => {
    useGSAP(() => {
        gsap.from('#obj', {
            scrollTrigger: {
                trigger: '#obj'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });
    }, []);
    
    return (
        <div className="p-[30px]">
            <h2 id="obj" className="mb-3 text-[25px] font-bold">Our Main Objective</h2>
            <p id="obj" className="leading-[30px]">By the end of the next six years, increase the proportion of FSWs accessing HIV testing services, quality health care, and human rights services, by 30% compared to the previous six years.</p>
        </div>
    );
}

export default OurObjective;