import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars } from 'react-icons/fa6';
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import { images, navLinks } from "../assets/constants";

const Navbar = () => {
    const [showMobileNav, setShowMobileNav] = useState(false);
    const navigate = useNavigate();

    useGSAP(() => {
        gsap.from('#logo', {
            duration: 1,
            opacity: 0,
            y: '-100%'
        });

        gsap.from('#nav-link', {
            duration: 1,
            opacity: 0,
            y: '-100%',
            stagger: 0.3
        });
    }, []);

    return (
        <>
            <div className="flex items-center justify-between w-full sm:p-[25px] px-[25px] p-2">
                <img src={images.logo} alt="Rocdi Logo" id="logo" />

                <div className="flex items-center justify-end gap-[30px]">
                    <ul className="sm:flex items-center justify-end gap-[30px] hidden">
                        {navLinks.map(link => {
                            const isActive = window.location.pathname === link.route || window.location.pathname.startsWith(`${link.route}/`);

                            return (
                                <li id="nav-link" onClick={() => navigate(link.route)} key={link.id} className={`cursor-pointer hover:text-blue ${isActive ? 'text-blue font-bold' : 'text-black'}`}>{link.label}</li>
                            )
                        })}
                    </ul>

                    <FaBars className="flex sm:hidden text-[30px] cursor-pointer" onClick={() => setShowMobileNav(prev => !prev)} />
                </div>
            </div>

            <ul className={`${showMobileNav ? 'top-[85px]' : 'top-[-100%]'} z-10 absolute left-0 flex transform transition-all flex-col items-start justify-center gap-[10px] w-full bg-white shadow-lg`}>
                {navLinks.map(link => {
                    const isActive = window.location.pathname === link.route || window.location.pathname.startsWith(`${link.route}/`);

                    return (
                        <li onClick={() => {
                            navigate(link.route);
                            setShowMobileNav(false);
                        }} key={link.id} className={`cursor-pointer w-full pt-0 px-[25px] py-3 border-b border-gray-200 hover:border-l-4 hover:border-l-blue hover:text-blue transform transition-all ${isActive ? 'text-blue font-bold border-l-blue border-l-4' : 'text-black'}`}>{link.label}</li>
                    );
                })}
            </ul>
        </>
    );
}

export default Navbar;