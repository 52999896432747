import { useGSAP } from '@gsap/react';
import { images } from '../assets/constants/index';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const WhoWeAre = () => {
    useGSAP(() => {
        gsap.from('#right', {
            scrollTrigger: {
                trigger: '#right'
            },
            duration: 1,
            opacity: 0,
            x: 200,
        });

        gsap.from('#left', {
            scrollTrigger: {
                trigger: '#left'
            },
            duration: 1,
            opacity: 0,
            x: -200,
            stagger: 0.3
        });
    }, []);

    return (
        <div className="flex md:flex-nowrap flex-wrap items-center justify-between gap-[30px] space-y-7 p-[30px] mt-[30px]">
            <div id='left' className="space-y-2">
                <h2 className='text-[30px] font-bold'>Know more about us</h2>
                <p className='leading-[30px]'>Round Care Development Initiatives, was founded in 2019, and incorporated by the Corporate Affairs of Nigeria (CAC/IT/No. 134169) as a Not-for-Profit sex worker's led community-based Organization in Nigeria,   on a strong desire to aid the growth of sex workers in their diversity through,  capacity building, meaningful communal engagement, encouraging the community to participate in decision making, and the prevention of gender-based violence, stigma, and discrimination, vulnerability to HIV/AIDS, STI, Hepatitis, TB and sexual and reproductive health issues</p>
            </div>
            <img id='right' src={images.whoweare} alt="Who we are" className="object-cover md:w-[50%] w-full h-[400px] md:rounded-ss-[100px] rounded-md" />
        </div>
    );
}

export default WhoWeAre;