import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const CoreValues = () => {
    useGSAP(() => {
        gsap.from('#cv-title', {
            scrollTrigger: {
                trigger: '#cv-title'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });

        gsap.from('#cv-box', {
            scrollTrigger: {
                trigger: '#cv-box',
            },
            duration: 1,
            opacity: 0,
            y: 130,
            scale: 0,
            stagger: 0.25
        });
    }, []);

    return (
        <div className="w-full p-[30px]">
            <h2 id="cv-title" className='text-[30px] font-bold'>Our core values</h2>

            <div className="flex flex-wrap justify-start gap-[25px] w-full mt-4">
                <div id="cv-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Respect</h1>
                    <p>We respect boundaries of our staff and the community we serve, experiences, choices and acknowledge the diversity and complexity of our community.</p>
                </div>
                <div id="cv-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Obedience</h1>
                    <p>We foster a culture of collaboration and accountability within the organization.</p>
                </div>
                <div id="cv-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Confidentiality</h1>
                    <p>Protecting the privacy and trust of our community members by maintaining the confidentiality of personal information, shared experiences and sensitive discussions.</p>
                </div>
                <div id="cv-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Determination</h1>
                    <p>We affirm our commitment to supporting the self -directed actions and decisions of sex workers and creating a just and equitable environment for all.</p>
                </div>
                <div id="cv-box" className="lg:w-[calc((100%/3)-25px)] md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <h1 className="text-[25px] font-bold mb-2">Integrity</h1>
                    <p>We uphold the highest ethical standard and moral discipline in all our actions, decisions, and interactions. We commit to transparency, accountability, and honesty in our words and deeds.</p>
                </div>
            </div>
        </div>
    );
}

export default CoreValues;