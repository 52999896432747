import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import { galleryImages } from '../assets/constants';

gsap.registerPlugin(ScrollTrigger);

const GalleryImgs = () => {
    useGSAP(() => {
        gsap.from('#gallery-img', {
            scrollTrigger: {
                trigger: '#gallery-img',
            },
            duration: 1,
            opacity: 0,
            y: 130,
            scale: 0,
            stagger: 0.25
        });
    }, []);

    return (
        <div className="flex justify-start gap-[30px] p-[30px] w-full flex-col md:flex-row flex-wrap">
            {galleryImages.map(item => (
                <div id='gallery-img' key={item.id} className="w-full md:w-[calc((100%/3)-30px)] shadow-md p-3 rounded-md">
                    <img src={item.img} alt={item.text} className='w-full h-[200px] object-center object-cover' />
                    <p className='mt-2'>{item.text}</p>
                </div>
            ))}
        </div>
    );
}

export default GalleryImgs;