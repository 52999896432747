import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { PaystackButton } from 'react-paystack';

const DonateForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState(0);

    const [msg, setMsg] = useState('');
    
    const [error, setError] = useState('');

    // const navigate = useNavigate();

    // const publicKey = 'pk_test_1a2be7417d9d01e48b0ef824033d67fcf7b77648';

    // const componentProps = {
    //     email,
    //     amount: parseInt(amount) * 100,
    //     metadata: {
    //         name,
    //     },
    //     publicKey,
    //     text: "Donate",
    //     onSuccess: () => navigate('/'),
    // }

    const handleDonate = (e) => {
        e.preventDefault();
    }

    return (
        <form className='md:w-[600px] sm:w-[350px] w-[300px] shadow-md mx-auto m-[30px] p-[20px] rounded-lg' onSubmit={handleDonate}>
            {error && <p className='bg-bgError text-textError p-2 w-full rounded-md mb-3'>{error}</p>}

            <div className="space-y-2">
                <label htmlFor="name">Your name</label>
                <input className='w-full border-2 border-gray-600 outline-none rounded-md p-[8px] py-[4px]' type="text" name="name" id="name" value={name} onChange={(e) => {
                    setError('');
                    setName(e.target.value);
                }} />
            </div>
            <div className="space-y-2 mt-[20px]">
                <label htmlFor="email">Your email</label>
                <input className='w-full border-2 border-gray-600 outline-none rounded-md p-[8px] py-[4px]' type="email" name="email" id="email" value={email} onChange={(e) => {
                    setError('');
                    setEmail(e.target.value);
                }} />
            </div>

            <div className="space-y-2 mt-[20px]">
                <label htmlFor="amount">Amount</label>
                <input className='w-full border-2 border-gray-600 outline-none rounded-md p-[8px] py-[4px]' type="number" name="amount" id="amount" value={amount} onChange={(e) => {
                    setError('');
                    setAmount(e.target.value);
                }} />
            </div>

            {/* <PaystackButton className='w-full text-center text-white bg-blue p-2 rounded-md mt-4' {...componentProps} /> */}

            {msg && (
                <div className='fixed top-[20px] right-[20px] bg-bgError text-textError px-[15px] py-[12px] rounded-sm'>
                    {msg}
                </div>
            )}

            <button className='w-full text-center text-white bg-blue p-2 rounded-md mt-4' onClick={() => {
                setMsg('This form is not available now, you can contribute your donation by making a transfer to the account below the form');

                setTimeout(() => {
                    setMsg('');
                }, 3000);
            }}>Donate</button>
        </form>
    )
}

export default DonateForm