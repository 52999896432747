import Header from "../components/Header";
import WhoWeAre from "../components/WhoWeAre";
import WhatWeDo from "../components/WhatWeDo";
import OurMission from "../components/OurMission";
import OurVision from "../components/OurVision";
import OurGoals from "../components/OurGoals";
import OurSuccessStory from "../components/OurSuccessStory";
import OurObjective from "../components/OurObjective";
import OurManagementStaff from "../components/OurManagementStaff";

const About = () => {
    return (
        <div>
            <Header title='About us' para='Get to know us more' button={false} />
            <WhoWeAre />
            <WhatWeDo />
            <div className="flex justify-start gap-[30px] p-[30px] w-full flex-col md:flex-row">
                <OurMission />
                <OurVision />
                <OurGoals />
            </div>
            <OurSuccessStory />
            <OurObjective />
            <OurManagementStaff />
        </div>
    );
}

export default About;