import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa6';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

const Header = ({ title, para, button }) => {
    const navigate = useNavigate();

    useGSAP(() => {
        gsap.from('#text', {
            duration: 1,
            opacity: 0,
            y: 70,
            stagger: 0.3
        });
    }, []);

    return (
        <header className="w-full h-[calc(100vh-180px)] text-white py-[30px] flex items-center justify-center">
            <div className="w-[70%] mx-auto text-center flex flex-col items-center justify-center gap-3">
                <h1 id='text' className='lg:text-[60px] md:text-[50px] text-[30px] lg:leading-[70px] font-bold'>{title}</h1>
                {para && <p id='text' className='md:text-[20px] text-[17px]'>{para}</p>}
                {button && <button id='text' className='flex items-center justify-center gap-2 p-2 px-4 rounded-[50px] bg-gold uppercase' onClick={() => navigate('/about-us')}>Learn more <FaArrowRight /></button>}
            </div>
        </header>
    );
}

export default Header;