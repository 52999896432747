import { useGSAP } from "@gsap/react";
import { managementStaffs } from "../assets/constants";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const OurManagementStaff = () => {
    useGSAP(() => {
        gsap.from('#ms-box', {
            scrollTrigger: {
                trigger: '#ms-box',
            },
            duration: 1,
            opacity: 0,
            y: 130,
            scale: 0,
            stagger: 0.25
        });
    }, []);
    
    return (
        <div className="flex justify-start gap-[30px] p-[30px] w-full flex-col md:flex-row flex-wrap">
            {managementStaffs.map(staff => (
                <div id="ms-box" key={staff.id} className="w-full md:w-[calc((100%/3)-30px)] shadow-md p-3 rounded-md">
                    <img src={staff.img} alt={staff.name} className="w-full h-[300px] object-center object-cover" />
                    <h3 className="text-[20px] font-bold">{staff.name}</h3>
                    <p className="text-[17px]">{staff.title}</p>
                </div>
            ))}
        </div>
    );
}

export default OurManagementStaff;