import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import { FaEnvelope, FaLocationDot, FaPhone } from "react-icons/fa6";
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const ContactDetails = () => {
    useGSAP(() => {
        gsap.from('#cd', {
            scrollTrigger: {
                trigger: '#cd'
            },
            duration: 1,
            opacity: 0,
            y: 70,
            stagger: 0.3
        });

        gsap.from('#map', {
            scrollTrigger: {
                trigger: '#map'
            },
            duration: 1,
            opacity: 0,
            y: 70,
        });
    }, []);

    return (
        <div className="p-[30px]">
            <div className="flex items-start justify-center flex-col gap-[25px]">
                <div className="flex items-center justify-start gap-[20px]">
                    <div id='cd' className="flex items-center justify-center bg-blue text-white rounded-full p-[18px]">
                        <FaLocationDot />
                    </div>
                    <div className="flex justify-center flex-col gap-[10px]">
                        <p id='cd' className="text-xl font-bold text-blue">Location</p>
                        <p id='cd' className="text-[12px] md:text-[20px]">06, Aiyegbami Street, Agege,<br /> Lagos, Nigeria</p>
                    </div>
                </div>
                <div className="flex items-center justify-start gap-[20px]">
                    <div id='cd' className="flex items-center justify-center bg-blue text-white rounded-full p-[18px]">
                        <FaPhone />
                    </div>
                    <div className="flex justify-center flex-col gap-[10px]">
                        <p id='cd' className="text-xl font-bold text-blue">Phone</p>
                        <p id='cd' className="text-[12px] md:text-[20px]">0813 094 1791<br /> 0805 563 0557</p>
                    </div>
                </div>
                <div className="flex items-center justify-start gap-[20px]">
                    <div id='cd' className="flex items-center justify-center bg-blue text-white rounded-full p-[18px]">
                        <FaEnvelope />
                    </div>
                    <div className="flex justify-center flex-col gap-[10px]">
                        <p id='cd' className="text-xl font-bold text-blue">Email</p>
                        <p id='cd' className="text-[12px] md:text-[20px]"><Link to='mailto:roundcaredevelopment@gmail.com'>roundcaredevelopment@gmail.com</Link></p>
                    </div>
                </div>
            </div>
            <div id='map' className="w-full mt-[20px]">
                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.160939331312!2d3.308029709196997!3d6.6269228219280025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9117212c60b3%3A0x469a2c5875a2dace!2s6%20Aiyegbami%20St%2C%20Agege%2C%20Lagos%20102212%2C%20Lagos!5e0!3m2!1sen!2sng!4v1716162294086!5m2!1sen!2sng" width="100%" height="290px" style={{ border: 'none' }} allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>
        </div>
    );
}

export default ContactDetails;