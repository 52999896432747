import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const OurGoals = () => {
    useGSAP(() => {
        gsap.from('#goals', {
            scrollTrigger: {
                trigger: '#goals'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });
    }, []);

    return (
        <div id='goals' className="md:w-[calc((100%/3)-30px)] w-full p-3 rounded-lg">
            <h2 className="mb-3 text-[24px] font-bold">Our Goals</h2>
            <ol className="list-disc">
                <li className="mb-3">Provide peer support, resources, and referrals to sex workers</li>
                <li className="mb-3">Advocate for policies and programs that support sex workers' rights and safety</li>
                <li className="mb-3">Create safe spaces for sex workers to organize and socialize</li>
                <li className="mb-3">Challenge stigma and discrimination against sex workers through public awareness and outreach</li>
                <li>Offer capacity building on health, safety and legal rights</li>
            </ol>
        </div>
    );
}

export default OurGoals;