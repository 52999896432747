import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const OurVision = () => {
    useGSAP(() => {
        gsap.from('#vision', {
            scrollTrigger: {
                trigger: '#vision'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });
    }, []);

    return (
        <div id="vision" className="md:w-[calc((100%/3)-30px)] w-full p-3 rounded-lg">
            <h2 className="mb-3 text-[24px] font-bold">Our Vision</h2>
            <p className="leading-[30px]">To see sex workers have access to quality health care and human rights services</p>
        </div>
    );
}

export default OurVision;