import { Link } from "react-router-dom";
import { images } from "../assets/constants";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const OurWorks = () => {
    useGSAP(() => {
        gsap.from('#ra-text', {
            scrollTrigger: {
                trigger: '#ra-text'
            },
            duration: 1,
            opacity: 0,
            y: 130,
            stagger: 0.3
        });

        gsap.from('#ra-box', {
            scrollTrigger: {
                trigger: '#ra-box',
            },
            duration: 1,
            opacity: 0,
            y: 130,
            scale: 0,
            stagger: 0.25
        });
    }, []);

    return (
        <div className="p-[30px] w-full">
            <h2 id='ra-text' className='text-[30px] font-bold'>See our recent activities</h2>
            <Link id='ra-text' to='/gallery' className="hover:text-blue uppercase">View all</Link>

            <div className="flex flex-wrap justify-start gap-[25px] w-full mt-4">
                <div id="ra-box" className="md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <img src={images.gallery1} alt='Gallery' className="w-full h-[200px] object-cover mb-2" />
                    <p>A volunteer of ROCDI conducting HTS on a community member</p>
                </div>
                <div id="ra-box" className="md:w-[calc((100%/2)-25px)] w-full shadow-md p-4 rounded-md">
                    <img src={images.gallery2} alt='Gallery' className="w-full h-[200px] object-cover mb-2" />
                    <p>Program officer of ROCDI engaging community stake holder in Makoko area of Lagos state</p>
                </div>
            </div>
        </div>
    );
}

export default OurWorks;