import DonateForm from "../components/DonateForm";
import Header from "../components/Header";

const Donate = () => {
    return (
        <div>
            <Header title='Donate now' para='Lend a helping hand and support our cause' button={false} />

            <DonateForm />

            <div className="flex items-center justify-center gap-[20px] p-[30px]">
                <div className="w-full p-[3px] rounded-full bg-blue" />
                <p className="uppercase text-[20px] text-blue">or</p>
                <div className="w-full p-[3px] rounded-full bg-blue" />
            </div>

            <div className="p-[30px] pt-0 flex items-center justify-center flex-col">
                <h1 className="text-[23px] text-center mb-2">Make transfer to the bank account below</h1>
                <div className="flex items-center justify-center flex-col gap-2">
                    <p className="text-[20px] text-center"><b>Bank Name</b>: Zenith Bank</p>
                    <p className="text-[20px] text-center"><b>Account Name</b>: Roundcare Development Initiative</p>
                    <p className="text-[20px] text-center"><b>Account Number</b>: 1016794019</p>
                </div>
            </div>
        </div>
    );
}

export default Donate;